import client from '../index'

export default {
  /**
   * 최근 게시글 목록 가져오기
   * @param {number} count 보여줄 게시글 수
   */
  async getLatestList (count) {
    const postData = {
      count: count
    }
    try {
      return await client.json.get('/community-recently', { params: postData })
    } catch (err) {
      // console.log('<GET> posted.getLatestList')
      // console.log(err)
    }
  },

  /**
   * 인기 게시글 목록 가져오기
   * @param {number} count 보여줄 게시글 수
   */
  async getPopularList (count) {
    const postData = {
      count: count
    }
    try {
      return await client.json.get('/community-popularity', { params: postData })
    } catch (err) {
      // console.log('<GET> posted.getPopularList')
      // console.log(err)
    }
  },

  /**
   * 카테고리별 게시글 목록 가져오기
   * @param {string} category 카테고리
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   * @param {String} query 검색어
   * @returns 게시글 목록, 총 게시물 수
   */
  async getList (category, count, nowPage, query) {
    if (nowPage < 0) return
    const postData = {
      category: category,
      count: count,
      nowpage: nowPage,
      query: query
    }
    try {
      return await client.json.get('/community', { params: postData })
    } catch (err) {
      // console.log('<GET> posted.getList')
      // console.log(err)
    }
  },

  /**
   * 내가 작성한 게시글 목록 가져오기
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   * @returns 게시글 목록, 총 게시물 수
   */
  async getMyPostedList (count, nowPage) {
    const postData = {
      count: count,
      nowpage: nowPage
    }
    try {
      return await client.json.get('/my-community', { params: postData })
    } catch (err) {
      // console.log('<GET> posted.my-community')
      // console.log(err)
    }
  },

  /**
   * 내가 작성한 게시글 목록 가져오기
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   * @returns 게시글 목록, 총 게시물 수
   */
  async getMyCommentList (count, nowPage) {
    const postData = {
      count: count,
      nowpage: nowPage
    }
    try {
      return await client.json.get('/my-comment', { params: postData })
    } catch (err) {
      // console.log('<GET> posted.my-comment')
      // console.log(err)
    }
  },

  /**
   * 게시글 작성
   * @param {string} category 카테고리
   * @param {string} title 제목
   * @param {string} content 본문
   * @param {boolean} commentAllow 댓글 허용
   * @param {boolean} highlight 글 하이라이트 여부
   */
  async post (category, title, content, commentAllow, highlight) {
    const postData = {
      category: category,
      title: title,
      content: content.toString()
        .replaceAll('<img src="data:image', '<img style="max-width: 100%;" src="data:image')
        .replaceAll('<iframe class="ql-video', '<iframe style="width: 100%; height: 45vh;" class="ql-video'),
      comment_allow: commentAllow,
      highlight: highlight
    }
    try {
      return await client.json.post('/community', postData)
    } catch (err) {
      // console.log('<POST> posted.post')
      // console.log(err)
    }
  },

  /**
   * 게시글 보기
   * @param {number} communityId pk
   */
  async detail (communityId) {
    try {
      return await client.json.get(`/community/${communityId}`)
    } catch (err) {
      // console.log('<GET> posted.detail')
      // console.log(err)
    }
  },

  /**
   * 게시글 수정
   * @param {number} communityId pk
   * @param {string} category 카테고리
   * @param {string} title 제목
   * @param {string} content 본문
   * @param {boolean} commentAllow 댓글 허용
   * @param {boolean} highlight 글 하이라이트 여부
   */
  async patch (communityId, category, title, content, commentAllow, highlight) {
    const postData = {
      category: category,
      title: title,
      content: content.toString()
        .replaceAll('<img src="data:image', '<img style="max-width: 100%;" src="data:image')
        .replaceAll('<iframe class="ql-video', '<iframe style="width: 100%; height: 45vh;" class="ql-video'),
      comment_allow: commentAllow,
      highlight: highlight
    }
    try {
      return await client.json.patch(`/community/${communityId}`, postData)
    } catch (err) {
      // console.log('<PATCH> posted.patch')
      // console.log(err)
    }
  },

  /**
   * 게시글 삭제
   * @param {number} communityId pk
   */
  async delete (communityId) {
    try {
      return await client.json.delete(`/community/${communityId}`)
    } catch (err) {
      // console.log('<DELETE> posted.delete')
      // console.log(err)
    }
  },

  /**
   * 게시글 좋아요
   *
   * @param {number} communityId pk
   */
  async like (communityId) {
    try {
      return await client.json.put(`/community/${communityId}`)
    } catch (err) {
      // console.log('<PUT> posted.like')
      // console.log(err)
    }
  },

  /**
   * 게시글 응원하기
   * @param {number} communityId pk
   * @param {*} coin 코인 양
   */
  async cheering (communityId, coin) {
    const postData = {
      coin: coin
    }
    try {
      return await client.json.post(`/community/${communityId}`, postData)
    } catch (err) {
      // console.log('<POST> posted.cheering')
      // console.log(err)
    }
  },

  /**
   * 게시글 하이라이트(코인 소모)
   * @param {number} communityId pk
   */
  async highlight (communityId) {
    try {
      return await client.json.put(`/community/highlight/${communityId}`)
    } catch (err) {
      // console.log('<PUT> posted.highlight')
      // console.log(err)
    }
  }

}
