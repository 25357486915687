
<template>
  <!-- <div v-bind:style="{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }"> -->
  <div v-bind:style="{ padding: '10px', height: 'auto',display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }">
    <div>
    <div :style="{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}">
      <span :style="{ padding: '10px 5px', fontSize: '1rem', fontWeight: '600', color: '#000' }">{{ title === 'latest' ? '최근 게시글' : title }}</span>
      <!-- <span v-show="this.$route.name === 'dashboard.latest'" :style="{ padding: '10px', fontSize: '0.8rem', fontWeight: '600', color: '#000'}" @click="more">more ></span> -->
    </div>
    <b-table
        id="list-table"
        responsive
        :small="true"
        :items="formartedItems"
        :fields="fieldsType === 'list' ? ListFields : SpanFields"
        :busy="isBusy"
        :hover="true"
        :fixed="true"
        :per-page="perPage"
        :no-border-collapse="true"
        :tbody-tr-class="rowClass"
        @row-clicked="goDetail">

      <!-- 테이블 로딩 시작-->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" :style="{color: '#032D98', paddingRight: '10px'}"></b-spinner>
        </div>
      </template>
      <!-- 테이블 로딩 끝-->

       <!-- 꽃달기, 제목 -->
       <template #cell(title)="data">
        <div v-if="data.item.type === 0" :style="{display: 'flex', alignItems: 'center'}">
          <div
              :style="{ borderRadius: '50%', width: '10px', height: '10px', background: '#ff0000', marginRight: '5px' }" />
              <div class="d-inline-block text-truncate" :style="{ paddingLeft: '10px' }">
                <span :style="{ color:'#acacac', fontSize: '1rem', fontWeight: 500 }">
              관리자에 의해 블라인드 처리된 게시글 입니다.
            </span>
          </div>
        </div>
        <div v-else :style="{ display: 'flex', justifyContent: 'start' }">
          <div v-if="data.item.highlight" :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
            <div
              :style="{ borderRadius: '50%', width: '10px', height: '10px', background: '#465FA0', marginRight: '5px' }" />
          </div>
          <div v-else :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
            <div
              :style="{ borderRadius: '50%', width: '10px', height: '10px', background: '#acacac', marginRight: '5px' }" />
          </div>
          <div class="d-inline-block text-truncate" :style="{ paddingLeft: '10px' }">
            <span :style="{ fontSize: '1rem', color:'#000' }">
              {{ data.item.title }}
            </span>
          </div>
        </div>
      </template>

      <!-- 작성자 -->
      <template #cell(name)="data">
        <span :style="{ fontSize: '0.8rem', color:'#3F3F3F', fontWeight: 500 }">
          {{ data.value }}
          <!-- {{ data.value.substr(0, data.value.length - 2) }}** -->
        </span>
      </template>

      <!-- 좋아요 수 -->
      <template #cell(like_total)="data">
        <div :style="{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', gap: '6px',background: '#F2F2F2', borderRadius: '47px'}">
        <span :style="{ fontSize: '0.7rem', color: '#032D98', fontWeight: 900 }">
          <i class="ri-thumb-up-line"></i>
        </span>
        <span :style="{ fontSize: '0.7rem', color: '#032D98', fontWeight: 500 }">
          {{ data.value.toLocaleString() }}
        </span>
      </div>
      </template>

      <!-- 응원 수 -->
      <template #cell(get_coin)="data">
        <div :style="{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', gap: '6px',background: '#F2F2F2', borderRadius: '47px'}">
        <span :style="{ fontSize: '0.7rem', color: '#032D98', fontWeight: 900 }">
          <i class="ri-vip-diamond-line"></i>
        </span>
        <span :style="{ fontSize: '0.7rem', color: '#032D98', fontWeight: 500 }">
          {{ data.value.toLocaleString() }}
        </span>
      </div>
      </template>

      <!-- 작성일 변경 -->
      <template #cell(date)="data">
        <span :style="{ fontSize: '0.7rem', color: '#7E7E7E', fontWeight: 500 }">
          {{ setDate(data.value) }}
        </span>
      </template>
    </b-table>
    </div>
    <div v-show="this.$route.name === 'dashboard.categoryList' || this.$route.name === 'dashboard.detail' || this.$route.name === 'dashboard.latest'">
      <iq-card :style="{boxShadow: 'none'}">
      <div class="iq-search-bar  d-md-block" :style="{padding: '0 !important'}" >
                    <form action="javascript:void(0);" class="searchbox" :style="{width: '100%'}">
                      <input type="text" class="text search-input" :style="{textAlign: 'center', background:'#fff', border:'1px #acacac solid'}" v-model="search"
                            :placeholder="category === 'latest' || category === undefined ? '최신 게시글을 검색해보세요.' : category + '의 게시글을 검색보세요'">
                      <a class="search-link" :style="{cursor: 'pointer'}" @click="getSearch($event)" ><i class="ri-search-line"></i></a>
                    </form>
                  </div>
    </iq-card>
    <b-pagination
    class="customPagination"
      v-model="currentPage"
      align='center'
      @page-click="pageClick"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="list-table"
      :first-number="true"
      :last-number="true" />
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ListForm',
  props: {
    isBusy: { type: Boolean },
    title: { type: String },
    items: { type: Array },
    total: { type: Number }, // 카테고리별 총 개수
    fieldsType: { type: String },
    color: {},
    getMore: {},
    path: {}
  },
  mounted () {
  },
  methods: {
    more () {
      this.$router.push({ name: `dashboard.${this.path}` })
    },
    rowClass (item, type) {
      if (item && type === 'row') {
        if (item.highlight) {
          return 'font-weight-bold'
        } else {
          return 'font-weight-light'
        }
      } else {
        return null
      }
    },
    setDate (data) {
      // var date = new Date()
      // var utcNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      //   date.getUTCDate(), date.getUTCHours(),
      //   date.getUTCMinutes(), date.getUTCSeconds())
      // const today = new Date(utcNow)
      // const item = new Date(data)
      // var utcTarget = Date.UTC(item.getUTCFullYear(), item.getUTCMonth(),
      //   item.getUTCDate(), item.getUTCHours(),
      //   item.getUTCMinutes(), item.getUTCSeconds())
      // const timeValue = new Date(utcTarget)
      // const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60)
      // if (betweenTime < 1) return '방금전'
      // if (betweenTime < 60) {
      //   return `${betweenTime}분전`
      // }
      // const betweenTimeHour = Math.floor(betweenTime / 60)
      // if (betweenTimeHour < 24) {
      //   return `${betweenTimeHour}시간전`
      // }

      // const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
      // if (betweenTimeDay < 365) {
      //   return `${betweenTimeDay}일전`
      // }

      // return `${Math.floor(betweenTimeDay / 365)}년전`

      const milliSeconds = new Date() - new Date(data)
      const seconds = milliSeconds / 1000
      if (seconds < 60) return '방금 전'
      const minutes = seconds / 60
      if (minutes < 60) return `${Math.floor(minutes)}분 전`
      const hours = minutes / 60
      if (hours < 24) return `${Math.floor(hours)}시간 전`
      const days = hours / 24
      if (days < 7) return `${Math.floor(days)}일 전`
      const weeks = days / 7
      if (weeks < 5) return `${Math.floor(weeks)}주 전`
      const months = days / 30
      if (months < 12) return `${Math.floor(months)}개월 전`
      const years = days / 365
      return `${Math.floor(years)}년 전`
    },
    pageClick (button, page) {
      this.$emit('setBusy')
      this.getMore(page - 1, this.search)
        .then(res => {
          if (res.status !== 200) {
            return alert('서버 오류')
          }
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
            this.$emit('setBusy')
            return
          }
          this.$emit('setBusy')
          this.$emit('setItem', res.data.community, res.data.total)
        })
    },
    goDetail (item) {
      if (this.$route.params.pk !== undefined) {
        if (this.$route.params.pk.toString() === item.community_id.toString()) {
          window.scrollTo(0, 0)
          return
        }
      }
      if (item.type === 0) {
        alert('관리자에 의해 블라인드된 게시글 입니다.')
        return
      }
      let itemCategory = this.$route.params.item
      let stop = false
      if (itemCategory === undefined) {
        for (let i = 0; i < this.categoryState.length; i++) {
          const list = this.categoryState[i].subcategory
          for (let j = 0; j < list.length; j++) {
            if (item.category === list[j].category_name) {
              itemCategory = this.categoryState[i].category_name
              stop = true
              break
            }
          }
          if (stop) {
            break
          }
        }
      }
      this.$router.push({
        name: 'dashboard.detail',
        params: {
          item: itemCategory,
          category: item.category,
          pk: item.community_id
        }
      })
    },
    getSearch () {
      this.$emit('setBusy')
      this.getMore(this.currentPage - 1, this.search)
        .then(res => {
          this.$emit('setBusy')
          this.$emit('setItem', res.data.community, res.data.total)
        })
    }
  },
  watch: {
    // page (newVal) {
    //   console.log(newVal)
    //   this.currentPage = this.$props.page - 1
    //   // this.$forceUpdate()
    //   // this.$emit('setBusy')
    //   // this.getMore(newVal - 1, this.search)
    //   //   .then(res => {
    //   //     if (res.status !== 200) {
    //   //       return alert('서버 오류')
    //   //     }
    //   //     if (res.data.resultCode === 'false') {
    //   //       alert('서버 오류')
    //   //       this.$emit('setBusy')
    //   //       return
    //   //     }
    //   //     this.$emit('setBusy')
    //   //     this.$emit('setItem', res.data.community, res.data.total)
    //   //   })
    // },
    currentPage (newVal) {
      if (this.$route.name === 'home.main') return
      this.$emit('setBusy')
      this.getMore(newVal - 1, this.search)
        .then(res => {
          this.$emit('setBusy')
          this.$emit('setItem', res.data.community, res.data.total)
        })
    }
  },
  computed: {
    ...mapGetters({
      categoryState: 'Category/categoryState'
    }),
    formartedItems () {
      if (!this.items) return []
      return this.items.map(item => {
        if (this.$route.params.pk !== undefined) {
          if (this.$route.params.pk.toString() === item.community_id.toString()) {
            item._rowVariant = 'secondary'
          } else {
            item._rowVariant = 'none'
          }
        }
        return item
      })
    }
  },
  data () {
    return {
      search: '',
      category: this.$route.params.category,
      itemList: [],
      perPage: 10, // 1페이지당 몇개씩
      currentPage: this.$props.page, // 현재 페이지
      SpanFields: [
        {
          key: 'highlight',
          label: '',
          thStyle: { width: '5%', display: 'none' },
          tdStyle: { width: '5%' },
          tdClass: 'text-center',
          thClass: 'text-center bg-white text-dark'
        },
        {
          key: 'title',
          label: '',
          thStyle: { width: '95%', display: 'none' },
          tdStyle: { width: '95%' },
          tdClass: 'text-start',
          thClass: 'text-start bg-white text-dark'
        }],
      ListFields: [
        {
          key: 'title',
          label: '',
          thStyle: { width: '50%', border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-start align-middle text-truncate'
        },
        {
          key: 'name',
          label: '',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'like_total',
          label: '',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'get_coin',
          label: '',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'date',
          label: '',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate'
        }
      ]
    }
  }
}
</script>
<style>
.page-item .page-link {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #797979 !important;
}
</style>
